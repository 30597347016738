<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import Pagination from "@/components/widgets/pagination";

import Tablesaw from 'tablesaw'
import {reloadTablesaw} from "@/helpers/datatable";

import store from "@/state/store";
import moment from "moment";
import {apiLogMethods} from "@/state/helpers";
import Loader from "@/components/widgets/loader";
import Modal from "@/components/modals/location-modal";

// 검색 기본값
const BASE_SEARCH = {
  dateField: 'created_at',
  field: 'target.fixedcrypt:name',
  keyword: '',
  startDate : '',
  endDate : '',
  ym: moment().format('YYYY-MM'),
  page: 1,
}

export default {
  components: { Layout, PageHeader, Pagination, Loader, Modal },
  data() {
    return {
      menuId: 200300,
      menuCode: "location",
      currentPage: 1,
      totalCount: 0,
      listData: [],
      isPageLoading: false,
      datepicker: {
        startDate : null,
        endDate : null,
      },
      search: BASE_SEARCH,
      // 검색키워드 항목
      fields: [
        { value: 'target.fixedcrypt:name', text: '대상자' },
        { value: 'user.fixedcrypt:name', text: '제공받는자' },
        { value: 'title', text: '제공내용' },
      ],
      yearMonths: [],
      showModal: false,
      modalData: {},
    };
  },
  created() {
    this.$changeMenu(this.menuId, this.menuCode)

    for(let i = 0; i < 4; i++) {
      let ym = moment().add(i * -1, 'months').format('YYYY-MM');
      this.yearMonths.push({ value: ym, text: ym });
    }
  },
  mounted () {
    Tablesaw._init();
  },
  beforeRouteUpdate(to, from, next) {
    if(to.fullPath !== from.fullPath) {
      this.initSearch(to.query)
      next();
    }
  },
  methods: {
    ...apiLogMethods,

    onRoutechange() {
      this.initSearch(this.$route.query)
      this.loadData()
    },

    // 데이터 추출
    loadData() {
      this.searchDate()

      if (!moment(this.search.startDate, 'YYYY-MM-DD', true).isValid()) {
        this.search.startDate = ""
      }

      if (!moment(this.search.endDate, 'YYYY-MM-DD', true).isValid()) {
        this.search.endDate = ""
      }

      if (!this.search.ym) {
        this.search.ym = moment().format('YYYY-MM');
      }

      let page = this.search.page

      // 검색 조건 결합
      let params = {
        ...this.$route.query,
        ...this.search,
        page: page
      };
      // console.log(params);

      this.isPageLoading = true
      store.dispatch("apiLogs/location", {params}).then((result) => {
        this.updateTableData(result.data, result.recordsFiltered)
        this.isPageLoading = false
      }, () => {
        this.isPageLoading = false
      })
    },

    // 데이터 가공
    updateTableData(data, total) {
      this.listData = data;
      this.totalCount = total;

      this.$nextTick(function () {
        // Tablesaw가 동적으로 처리되지 않아 별도 처리
        reloadTablesaw(this.$refs.tablesaw)
      })
    },

    // 라우트 변경 ( 브라우져 뒤로가기 구현 )
    changeRouter() {
      // 검색조건에 검색기간 설정
      this.searchDate()

      if (!this.search.ym) {
        this.search.ym = moment().format('YYYY-MM');
      }

      if (!moment(this.search.startDate, 'YYYY-MM-DD', true).isValid()) {
        this.search.startDate = "all"
      }

      if (!moment(this.search.endDate, 'YYYY-MM-DD', true).isValid()) {
        this.search.endDate = "all"
      }

      let page = this.search.page
      let params = {page: page};

      // 쿼리스트링 결합
      let query = {
        ...this.$route.query,
        ...this.search,
        ...params
      };

      // 히스토리 추가
      this.$router.push({query})
    },

    // 페이징 처리
    changePage(page) {
      if (this.search.page != page) {
        this.currentPage = this.search.page = page
        this.changeRouter()
      }
    },

    // 검색 초기값 설정
    initSearch(routeQuery) {
      if (routeQuery.startDate != "all" && !moment(routeQuery.startDate, 'YYYY-MM-DD', true).isValid()) {
        this.datepicker.startDate = new Date(moment().format('YYYY-MM-DD'))
      }
      if (routeQuery.endDate != "all" && !moment(routeQuery.endDate, 'YYYY-MM-DD', true).isValid()) {
        this.datepicker.endDate = new Date(moment().format('YYYY-MM-DD'))
      }

      if (!routeQuery.ym) {
        routeQuery.ym = moment().format('YYYY-MM');
      }

      this.search = Object.assign({}, {...BASE_SEARCH, ...routeQuery})
      this.search.page = this.search.page * 1
      this.currentPage = this.search.page

      // 라우트가 변경되면 검색기간 변경
      if (this.search.startDate) {
        this.datepicker.startDate = new Date(this.search.startDate)
      }
      if (this.search.endDate) {
        this.datepicker.endDate = new Date(this.search.endDate)
      }
    },

    // 검색 실행
    searchData() {
      this.currentPage = this.search.page = 1

      // 라우트가 변경되면 loadData 호출
      this.changeRouter()
    },

    // 검색기간 설정
    searchDate() {
      if (this.datepicker.startDate) {
        this.search.startDate = moment(this.datepicker.startDate).format('YYYY-MM-DD')
      } else {
        this.search.startDate = "all"
      }

      if (this.datepicker.endDate) {
        this.search.endDate = moment(this.datepicker.endDate).format('YYYY-MM-DD')
      } else {
        this.search.endDate = "all"
      }

      if (!this.search.ym) {
        this.search.ym = moment().format('YYYY-MM');
      }
    },

    searchYM(ym) {
      this.search.ym = ym
      if (!ym) {
        this.search.ym = moment().format('YYYY-MM');
      }
      if (this.search.ym == moment().format('YYYY-MM')) {
        this.datepicker.startDate = new Date(moment().format('YYYY-MM-DD'))
        this.datepicker.endDate = new Date(moment().format('YYYY-MM-DD'))
      } else {
        this.datepicker.startDate = new Date(moment(this.search.ym +'-01').format('YYYY-MM-DD'))
        this.datepicker.endDate = new Date(moment(this.search.ym +'-01').format('YYYY-MM-DD'))
      }

      this.searchData()
    },

    variantYM(ym) {
      if (this.search.ym == ym || (ym == moment().format('YYYY-MM') && !this.$route.query.ym)) {
        return "info"
      } else {
        return (ym == this.$route.query.ym) ? "info" : "outline-secondary"
      }
    },

    disabledDate(date) {
      const today = new Date(moment(this.search.ym));
      let firstDay = new Date(today.getFullYear(), today.getMonth(), 1, 0, 0, 0);
      let lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0, 23, 59, 59);
      return (date < firstDay) || (date > lastDay);
    },

    openModal: function(event) {
      this.modalData = event;
    },
  },
  watch: {
    $route: {
      handler: "onRoutechange",
      immediate: true,
      deep: true,
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader />
    <Modal v-if="showModal" :modal-data="modalData" @close="showModal = false" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card min-content">
          <div class="card-body">
            <div>
              <b-form class="px-2" @submit.prevent="searchData">
                <div class="form-group row mb-2 pb-2 search-line">
                  <label class="col-form-label col-sm-2 text-nowrap">년/월 선택</label>
                  <div class="col-sm input-group">
                    <div class="col-sm-2 w-auto text-nowrap">
                      <b-button
                          v-for="ym in yearMonths"
                          :key="ym.value"
                          :variant="variantYM(ym.value)"
                          @click="searchYM(ym.value)"
                          class="spacing-10 me-1"
                      >{{ ym.text }}</b-button>
                    </div>
                  </div>
                </div>
                <div class="form-group row mb-2 pb-2 search-line">
                  <label class="col-form-label col-sm-2 text-nowrap">기간 검색</label>
                  <div class="col-sm input-group">
                    <div class="col-sm-2 pe-2 mb-1 search-date">
                      <date-picker
                          input-class="form-control"
                          v-model:value="datepicker.startDate"
                          value-type="date"
                          :disabled-date="disabledDate"
                      ></date-picker>
                    </div>
                    <div class="col-sm-2 pe-2 mb-1 search-date">
                      <date-picker
                          input-class="form-control"
                          v-model:value="datepicker.endDate"
                          value-type="date"
                          :disabled-date="disabledDate"
                      ></date-picker>
                    </div>
                    <div class="col-sm-2 w-auto text-nowrap ms-1 pt-2 text-danger">
                      ※ 3개월 이전 조회 불가
                    </div>
                  </div>
                </div>
                <div class="form-group row mb-3 pb-2 search-line">
                  <label class="col-form-label col-sm-2 text-nowrap">키워드 검색</label>
                  <div class="col-sm input-group text-nowrap">
                    <b-form-select
                        for="field"
                        v-model="search.field"
                        :options="fields"
                        class="form-control"
                        style="width:110px;flex:none"
                    ></b-form-select>
                    <b-form-input
                        for="keyword"
                        v-model="search.keyword"
                        class="form-control"
                    ></b-form-input>
                  </div>
                </div>
                <div class="row justify-content-between pb-3">
                  <div class="col">
                    <b-button type="submit" variant="primary">검색하기</b-button>
                  </div>
                </div>
              </b-form>
              <div class="position-relative" style="min-height: 130px;">
                <table ref="tablesaw" class="tablesaw table align-middle table-nowrap table-hover" data-tablesaw-mode="columntoggle">
                  <thead class="table-light">
                  <tr>
                    <th scope="col" class="wd-60" data-tablesaw-priority="6">순번</th>
                    <th scope="col" class="wd-70" data-tablesaw-priority="5">취득경로</th>
                    <th scope="col" class="wd-70" data-tablesaw-priority="persist">대상자</th>
                    <th scope="col" class="wd-70" data-tablesaw-priority="persist">받는자</th>
                    <th scope="col" class="text-start" data-tablesaw-priority="persist">제공내용</th>
                    <th scope="col" class="wd-80" data-tablesaw-priority="persist">조회일자</th>
                    <th scope="col" class="wd-60" data-tablesaw-priority="3">위치갯수</th>
                    <th scope="col" class="wd-120" data-tablesaw-priority="4">열람일</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="list in listData" :key="list.id">
                    <td>{{ list.rownum }}</td>
                    <td>{{ list.type }}</td>
                    <td><a href="javascript:void(0);" @click="openModal(list), (showModal = true)">{{ list.target_user }}</a></td>
                    <td>{{ list.member_name }}</td>
                    <td class="text-start ellipsis spacing-5"><span>{{ list.title }}</span></td>
                    <td>{{ list.period }}</td>
                    <td>{{ list.count }}</td>
                    <td>{{ list.register_date }}</td>
                  </tr>
                  </tbody>
                </table>
                <div class="position-absolute base-list-message pt-5" v-show="!isPageLoading && listData.length === 0">조회된 내역이 없습니다.</div>
                <Loader :loading="isPageLoading" class="position-absolute base-list-loader mt-5"></Loader>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <Pagination :total="totalCount" v-model:page="currentPage" @changePage="changePage($event)"></Pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

