<script>
export default {
  name : 'Modal',
  props : ["modalData"],
  data() {
    return {
      data : this.modalData,
      showModal : false,
    }
  },
  mounted() {
//    console.log(this.modalData)
    this.showModal = true;
  },
  methods: {
    openModal() {
      window.history.pushState({}, '', '#modal');
      window.onpopstate = history.onpushstate = () => {
        this.showModal = false
      }
    },

    closeModal() {
      this.$emit('close');
      window.history.back()
    },
  }
}
</script>

<template>
  <b-modal
      v-model="showModal"
      id="modal-box"
      @show="openModal"
      @hidden="closeModal"
      centered
      title="상세정보"
      title-class="font-16 fw-bold"
      hide-footer
  >
    <div class="row mb-2 align-items-center">
      <div class="mb-2">
        <div class="my-2">
          <div class="mb-2 fw-semibold">취득경로</div>
          <div class="form-control bg-light">{{ data.type }}&nbsp;</div>
        </div>
        <div class="mb-1 fw-semibold">대상자</div>
        <div class="form-control bg-light">{{ data.target_user }}</div>
      </div>
      <div class="my-2">
        <div class="mb-2 fw-semibold">제공받는자</div>
        <div class="form-control bg-light">{{ data.member_name }}&nbsp;</div>
      </div>
      <div class="my-2">
        <div class="mb-2 fw-semibold">제공내용</div>
        <div class="form-control bg-light">{{ data.title }}&nbsp;</div>
      </div>
      <div class="my-2">
        <div class="mb-1 fw-semibold">조회일자</div>
        <div class="form-control bg-light">{{ data.period }}&nbsp;</div>
      </div>
      <div class="my-2">
        <div class="mb-1 fw-semibold">열람일</div>
        <div class="form-control bg-light">{{ data.created_at }}&nbsp;</div>
      </div>
    </div>
  </b-modal>
</template>
